import React from "react"
import JanowoTwoHeader from "@/components/estates/janowotwo/janowoTwoHeader"
import Search from "@/components/search/searchJanowoPark2"
import list from "@/flats/flatList/janowoPark2Array"
import Description from "@/components/estates/janowotwo/description"
import Visual from "@/components/estates/janowotwo/visual"
import Localization from "@/components/estates/janowotwo/localization"
import LogBuilding from "@/components/estates/logBuilding"
import Layout from "@/components/layout"
import Helmet from "react-helmet"
import Modal from "@/components/popup2/popup"
import JanowoTwoVisual from "@/components/estates/janowotwo/janowoTwoVisual"

import { logs } from "@/data/diary/buildingLogsOJP2"
import EstatePlan from "@/components/estates/estatePlan"
import Video from "@/components/estates/video"
import EstateVisualDownload from "@/components/estates/estateVisualDownload"
import EstateDisclaimer from "@/components/estates/estateDisclaimer"
import planImage from "@/images/z-8/z-7PZT.png"
import EstateHeader from "@/components/estates/estateHeader"

import wizImage from "@/images/z-8/z-7g-wide.jpg"
import wiz2 from "@/images/janowo-2/wiz-janowo-4.jpg"
import wiz5 from "@/images/z-7/z-7f.jpg"

function JanowoTwo({ location }) {
  const scrollDestination =
    location && location.state && location.state.position

  const sortedList = list.sort((a, b) => (!!a[13] ? 1 : -1))

  return (
    <>
      <Helmet>
        <title>WILLBUD | Osiedle Janowo Park II</title>
      </Helmet>
      <Layout>
        <EstateHeader
          title={`Osiedle Janowo Park II`}
          place={"RUMIA, UL. BŁOŃ JANOWSKICH"}
          buttonText={"Zapytaj o ostatni lokal z ROI 9,5%"}
          completed
        />
        <JanowoTwoVisual
          slides={[wizImage, wiz2, wiz5]}
          alt={`Janowo Park II Wizualizacja`}
        />
        <JanowoTwoHeader scrollDestination={scrollDestination} />
        <Description />
        <Visual />
        <EstateVisualDownload />
        {/*  <JanowoTwoVisual image={wizImage2} />*/}
        <Search estate={"janowo"} list={sortedList} />

        <EstatePlan image={planImage} scrollDestination={scrollDestination} />
        <Localization estate={"janowo2"} />
        <Video video="https://www.youtube.com/embed/xsh54VpDxOc" />
        <LogBuilding
          logs={logs}
          anchor={`/pl/oferta/osiedle-janowo-park-2/#diary`}
        />
        <EstateDisclaimer />
        <Modal />
      </Layout>
    </>
  )
}

export default JanowoTwo
